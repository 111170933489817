<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3 class="mx-auto">
            Welcome {{name}}
          </h3>
        </v-card-title>
         <div style="width:60%;text-align:center;min-width:300px;display:inline-block;position:relative;"
         v-if="isCandidate">
           <ul>
              <li>
                The candidate must fill all the information that is being sought in this form.
              </li>
              <li>
                Incomplete form will be rejected.
              </li>
              <li>
                This application does not ensure a Job Offer from the organization.
              </li>
              <li>
                The information from this form is only used for screening, shortlisting and interviewing.
              </li>
            </ul>
         </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      isCandidate: true,
    };
  },
  methods: {
    async getType() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('usersList').doc(this.$store.state.user.currentUser.email);
      const doc = await type.get();
      const info = doc.data();
      this.name = info.name;
      this.$store.state.user.currentUser.name = info.name;
      if (info.admin === undefined && info.superAdmin === undefined) {
        this.$store.state.user.currentUser.formType = info.postRecruitment;
      } else {
        this.isCandidate = false;
      }
      // console.log(this.$store.state.user.currentUser.formType, 'fetch at index level');
    },
  },
  computed: {
    name: {
      get() {
        if (this.$store.state.user.currentUser.name === undefined && this.$store.state.user.currentUser.email !== undefined) {
          return this.$store.state.user.currentUser.email;
        }
        return this.$store.state.user.currentUser.name;
      },
      set(newName) {
        return newName;
      },
    },
  },
  mounted() {
    if (this.$store.state.user.currentUser.email !== undefined) {
      this.getType();
    }
  },
};
</script>
